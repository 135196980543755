// @ts-nocheck
// This file is generated by Umi automatically
// DO NOT CHANGE IT MANUALLY!
import React from 'react';
import { HelmetProvider } from '/root/workspace/__flow_work/__flow_builds/a3679a88-0b47-4629-985d-cd4b152be050/workspace/eiam-opensource_nsmB/eiam-ui/node_modules/.pnpm/@umijs+renderer-react@4.3.36_nnrd3gsncyragczmpvfhocinkq/node_modules/@umijs/renderer-react';
import { context } from './helmetContext';

export const innerProvider = (container) => {
  return React.createElement(HelmetProvider, { context }, container);
}
